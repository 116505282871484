
.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 80px;
  right: 20px;
  min-width: 250px;

  .message {
     position: absolute;
     top: 30px;

     .messageContent {
        min-width: 220px;
     }
  }

  .copyButton {
    button {
      visibility: hidden;
    }
  }

  .copyButton:hover {
    button {
      visibility: visible;
    }
  }
}