:global(#tokenTesterForm) {
  padding: 20px;
}

.useCache {
  margin-right: auto;
}

.reloadButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 0 24px 0;
}

.profileContainer {
  padding-bottom: 100px;
}

.tokensContainer {
  text-align: initial;
}
